"use strict";

/**
 * @class HELPAlert  base utilize class that privides helper functions in alerts messages
 */

// Class definition
var HELPAlert = function () {
    return {

        /**
         */
        SAlert: function (args) {

            let options = {};
            options.color = 'danger';
            options.colorButton = 'danger';
            options.title = 'Ingrese un titulo!';
            options.message = 'Ingrese un mensage!';
            options.buttonOkText = 'Aceptar';

            options.callback = () => { }

            for (var prop in args) {
                if (options.hasOwnProperty(prop)) {
                    options[prop] = args[prop];
                }
            }

            Swal.fire({
                html: `<div class="alert bg-light-${options.color} d-flex align-items-center p-3 mb-0 border-${options.color} border-top-0 border-end-0 border-bottom-0 border-3 rounded-1">
                    <div class="d-flex flex-column">
                        <h4 class="mb-1 text-${options.color}">${options.title}</h4>
                        <span>${options.message}</span>
                    </div>
                </div>`,
                buttonsStyling: false,
                confirmButtonText: options.buttonOkText,
                customClass: {
                    confirmButton: `btn btn-sm btn-${options.colorButton} fs-3 py-2 px-4`,
                    htmlContainer: "m-0 text-start",
                    actions: "m-0 mt-2",
                    popup: "p-4"
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                keydownListenerCapture: true,
            }).then((result) => {

                if (result.isConfirmed) {
                    return options.callback();
                }

            });

        },

        /**
         */
        SConfirm: function (args) {

            let options = {};
            options.color = 'primary';
            options.colorButton = 'primary';
            options.title = 'Ingrese un titulo!';
            options.message = 'Ingrese un mensage!';
            options.buttonOkText = 'Aceptar';
            options.buttonCancelText = 'Cancelar';

            options.callback = () => { }
            options.cancelback = () => { }

            for (var prop in args) {
                if (options.hasOwnProperty(prop)) {
                    options[prop] = arguments[0][prop];
                }
            }

            Swal.fire({
                html: `<div class="alert bg-light-${options.color} d-flex align-items-center p-3 mb-0 border border-${options.color} border-top-0 border-end-0 border-bottom-0 border-3 rounded-1">
                <div class="d-flex flex-column">
                    <h4 class="mb-1 text-${options.color}">${options.title}</h4>
                    <span>${options.message}</span>
                </div>
            </div>`,
                buttonsStyling: false,
                confirmButtonText: options.buttonOkText,
                cancelButtonText: options.buttonCancelText,
                showCancelButton: true,
                focusConfirm: false,
                reverseButtons: true,
                customClass: {
                    confirmButton: `btn btn-sm btn-${options.colorButton} fs-3 py-2 px-4`,
                    cancelButton: 'btn btn-sm btn-light fs-3 py-2 px-4',
                    htmlContainer: "m-0 text-start",
                    actions: "m-0 mt-2",
                    popup: "p-4"
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                keydownListenerCapture: true,
            }).then((result) => {

                if (result.isConfirmed) {
                    return options.callback();
                }
                else {
                    return options.cancelback();
                }

            });

        },

        /**
         */
        SConfirmCancel: function (args) {

            let options = {};
            options.color = 'primary';
            options.colorButton = 'primary';
            options.colorCancel = 'danger'
            options.title = 'Ingrese un titulo!';
            options.message = 'Ingrese un mensage!';
            options.buttonOkText = 'Aceptar';
            options.buttonCancelText = 'Cancelar';

            options.callback = () => { }
            options.cancelback = () => { }

            for (var prop in args) {
                if (options.hasOwnProperty(prop)) {
                    options[prop] = arguments[0][prop];
                }
            }

            Swal.fire({
                html: `<div class="alert bg-light-${options.color} d-flex align-items-center p-3 mb-0 border border-${options.color} border-top-0 border-end-0 border-bottom-0 border-3 rounded-1">
                <div class="d-flex flex-column">
                    <h4 class="mb-1 text-${options.color}">${options.title}</h4>
                    <span>${options.message}</span>
                </div>
            </div>`,
                buttonsStyling: false,
                confirmButtonText: options.buttonOkText,
                cancelButtonText: options.buttonCancelText,
                showCancelButton: true,
                focusConfirm: false,
                reverseButtons: true,
                customClass: {
                    confirmButton: `btn btn-sm btn-${options.colorButton} fs-3 py-2 px-4`,
                    cancelButton: `btn btn-sm btn-${options.colorCancel} fs-3 py-2 px-4`,
                    htmlContainer: "m-0 text-start",
                    actions: "m-0 mt-2",
                    popup: "p-4"
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                keydownListenerCapture: true,
            }).then((result) => {

                if (result.isConfirmed) {
                    return options.callback();
                }
                else {
                    return options.cancelback();
                }

            });

        },
        SSimpleInput: function (args) {

            let options = {};
            options.color = 'primary';
            options.colorButtonSend = 'primary';
            options.title = 'Ingrese un titulo!';
            options.message = 'Ingrese un mensage!';
            options.buttonSendText = 'Enviar';
            options.buttonConfirmText = 'Enviar';

            options.callback_send = () => { }

            for (var prop in args) {
                if (options.hasOwnProperty(prop)) {
                    options[prop] = arguments[0][prop];
                }
            }

            Swal.fire({
                html: `<div class="alert bg-light-${options.color} d-block p-3 mb-0 border border-${options.color} border-top-0 border-end-0 border-bottom-0 border-3 rounded-1 input-alert">
                <div class="d-flex flex-column">
                    <h4 class="mb-1 text-${options.color}">${options.title}</h4>
                    <div class="d-flex flex-column flex-grow-1">
                        <input type="text" class="form-control mt-6 p-2" id="sinp-text-input"/>
                        <div class="d-flex justify-content-center mt-6">
                            <button class="btn btn-success sinp-btn" id="sinp-btn">${options.buttonSendText}</button>
                        </div>
                    </div>
                </div>
            </div>`,
                buttonsStyling: false,
                confirmButtonText: options.buttonConfirmText,
                focusConfirm: false,
                reverseButtons: true,
                customClass: {
                    confirmButton: `btn btn-sm btn-${options.colorButtonSend} fs-3 py-2 px-4 disabled sinp-okbtn`,
                    htmlContainer: "m-0 text-start",
                    actions: "m-0 mt-2",
                    popup: "p-4"
                },
                allowOutsideClick: false,
                allowEscapeKey: false,
                allowEnterKey: false,
                keydownListenerCapture: true,
            }).then((result) => {

                if (result.isConfirmed) {
                    return options.callback_send();
                }
            });
        },
    }
}();

// Webpack support
if (typeof module !== 'undefined' && typeof module.exports !== 'undefined') {
    module.exports = HELPAlert;
}